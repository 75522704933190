import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import MainBanner from "../components/ITStartup/MainBanner"
import Features from "../components/ITStartup/Features"
import ServicesArea from "../components/ITStartup/ServicesArea"
import MilestonesAchievedSoFar from "../components/Common/MilestonesAchievedSoFar"
import RecentWorks from "../components/Common/RecentWorks"
import Partner from "../components/Common/Partner"
import Footer from "../components/_App/Footer"
import StartupIndia from "../components/StartupIndia/StartupIndia";
import ClutchTestimonials from "../components/Common/ClutchTestimonials";
import WhyChoose from "../components/Hosting/WhyChoose"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Navbar />
    <MainBanner />
    <Features />
    <ServicesArea />
    <StartupIndia />
    {/*<Team />*/}
      <WhyChoose/>
    <MilestonesAchievedSoFar />
    <RecentWorks />
    {/*<PricingStyleOne />*/}
    {/*<FeedbackStyleFour />*/}
      <ClutchTestimonials />
      {/*<RecentWorks />*/}
    {/*<FeedbackStyleTwo />*/}
    <Partner />
    {/*<BlogPost />*/}
    <Footer />
  </Layout>
)

export default IndexPage
