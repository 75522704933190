import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import { useStaticQuery, graphql } from "gatsby"

// Shape Images
import Shape2 from "../../assets/images/shape2.svg"
import Shape4 from "../../assets/images/shape4.svg"

import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  items: 4,
  loop: true,
  nav: false,
  autoplay: true,
  margin: 30,
  dots: false,
  responsive: {
    0: {
      items: 1,
    },
    576: {
      items: 3,
    },
    768: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    1500: {
      items: 4,
    },
  },
}

const query = graphql`
  {
    strapiOurRecentWorksHeader {
      title
      shortText
    }

    allStrapiProjects {
      nodes {
        id
        title
        image {
          childImageSharp {
             fluid(webpQuality: 80, jpegProgressive:true, jpegQuality: 80, maxHeight:450 ) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        shortText
        longDescription
        slug
      }
    }
  }
`

const RecentWorks = () => {
  const data = useStaticQuery(query)
  const {
    strapiOurRecentWorksHeader: { title },
    allStrapiProjects: { nodes: projects },
  } = data

  // Slider
  const [display, setDisplay] = React.useState(false)
  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="works-area pt-80 pb-50 container">
      <div className="">
        <div className="section-title">
          <h2>{title}</h2>
          <div className="bar"/>
          {/*<p>{shortText}</p>*/}
        </div>
      </div>
      <div className="recent-work-box">
      {display ? (
        <OwlCarousel
          className="works-slides owl-carousel owl-theme"
          {...options}
        >
          {projects.map((project, index) => (
            <div className="single-works" key={index}>
            <Link to={`/portfolio/${project.slug}`} key={project.id} style={{ display: 'block' }}>
              <img src={`${project.image.childImageSharp.fluid.srcWebp}`} className="w-100" alt="" />
              {/*<div style={{backgroundImage: "url('"+project.image.childImageSharp.fluid.srcWebp+"')"}} className={"portfolio-bg-image-div"}></div>*/}
              {/*<img*/}
              {/*  src={project.image.childImageSharp.fluid.src}*/}
              {/*  alt={project.title}*/}
              {/*  className="project-featured-image"*/}
              {/*/>*/}

              {/*<Link to={`/portfolio/${project.slug}`} className="icon">*/}
              {/*  <Icon.Settings />*/}
              {/*</Link>*/}

              <div className="works-content">
                <h3>{project.title}</h3>
                <p>{project.shortText}</p>
              </div>
              </Link>
            </div>
          ))}
        </OwlCarousel>
      ) : (
        ""
      )}
      </div>

      {/* Shape Images */}
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape2 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
    </div>
  )
}

export default RecentWorks
