import React from "react"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"

// Banner Images
import Man from "../../assets/images/banner-image/man.png";
import Code from "../../assets/images/banner-image/code.png";
import Carpet from "../../assets/images/banner-image/carpet.png";
import Bin from "../../assets/images/banner-image/bin.png";
import Book from "../../assets/images/banner-image/book.png";
import Dekstop from "../../assets/images/banner-image/dekstop.png";
import Dot from "../../assets/images/banner-image/dot.png";
import FlowerTopBig from "../../assets/images/banner-image/flower-top-big.png";
import FlowerTop from "../../assets/images/banner-image/flower-top.png";
import Keyboard from "../../assets/images/banner-image/keyboard.png";
import Pen from "../../assets/images/banner-image/pen.png";
import Table from "../../assets/images/banner-image/table.png";
import TeaCup from "../../assets/images/banner-image/tea-cup.png";
import HeadPhone from "../../assets/images/banner-image/headphone.png";
// import MainPic from "../../assets/images/banner-image/main-pic.png";

// Shape Images
import Shape1 from "../../assets/images/shape1.png"
import Shape2 from "../../assets/images/shape2.svg"
import Shape3 from "../../assets/images/shape3.svg"
import Shape4 from "../../assets/images/shape4.svg"
import Shape5 from "../../assets/images/shape5.png"
import Trusted from "../../assets/icons/trusted.png"
import Star from "../../assets/icons/star.png"

const query = graphql`
  {
    strapiItStartupBanner {
      title
      shortText
      btnText
      btnUrl
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const MainBanner = () => {
  const data = useStaticQuery(query)
  const {
    strapiItStartupBanner: { title, shortText, btnText, btnUrl, image },
  } = data

  return (
    <div className="main-banner">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-5">
                <div className="hero-content">
                  <h1>{title}</h1>
                  <p>{shortText}</p>
                  <Link to={btnUrl} className="btn btn-primary">
                    {btnText}
                  </Link>
                </div>
                <div className="d-flex flex-column mt-5 gap-2 align-items-start p-3">
                  <div className="d-flex gap-sm-3 gap-2 flex-column w-100 justify-content-between flex-xl-row align-items-start align-items-xl-center">
                    <div className='d-flex gap-2 align-items-center'>
                      <img src={Trusted} alt="Trusted by clients icon" width={30} height={30} />
                      <h6 className='mt-1'>Trusted by our clients</h6>
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                      <p className='mb-0'>Excellent</p>
                      <div>
                        <img src={Star} alt="star icon" width={20} height={20} />
                        <img src={Star} alt="star icon" width={20} height={20} />
                        <img src={Star} alt="star icon" width={20} height={20} />
                        <img src={Star} alt="star icon" width={20} height={20} />
                        <img src={Star} alt="star icon" width={20} height={20} />
                      </div>
                    </div>
                  </div>
                  <p style={{textAlign: "left"}}>We help leaders, from Startups to Fortune 500, deliver technology on time and within budget</p>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 mt-5 mt-md-0">
                <div className="banner-image">
                    <img
                        src={Man}
                        className="animate__animated animate__fadeInDown animate__delay-0.1s"
                        alt="man"
                    />

                    <img
                        src={Code}
                        className="animate__animated animate__fadeInUp animate__delay-0.1s"
                        alt="code"
                    />

                    <img
                        src={Carpet}
                        className="animate__animated animate__fadeInLeft animate__delay-0.1s"
                        alt="carpet"
                    />

                    <img
                        src={Bin}
                        className="animate__animated animate__zoomIn animate__delay-0.1s"
                        alt="bin"
                    />

                    <img
                        src={Book}
                        className="animate__animated animate__bounceIn animate__delay-0.1s"
                        alt="book"
                    />

                    <img
                        src={Dekstop}
                        className="animate__animated animate__fadeInDown animate__delay-0.1s"
                        alt="dekstop"
                    />

                    <img
                        src={Dot}
                        className="animate__animated animate__zoomIn animate__delay-0.1s"
                        alt="dot"
                    />

                    <img
                        src={FlowerTopBig}
                        className="animate__animated animate__fadeInUp animate__delay-0.1s"
                        alt="flower-top-big"
                    />

                    <img
                        src={FlowerTop}
                        className="animate__animated animate__rotateIn animate__delay-0.1s"
                        alt="flower-top"
                    />

                    <img
                        src={Keyboard}
                        className="animate__animated animate__fadeInUp animate__delay-0.1s"
                        alt="keyboard"
                    />

                    <img
                        src={Pen}
                        className="animate__animated animate__zoomIn animate__delay-0.1s"
                        alt="pen"
                    />

                    <img
                        src={Table}
                        className="animate__animated animate__zoomIn animate__delay-0.1s"
                        alt="table"
                    />

                    <img
                        src={TeaCup}
                        className="animate__animated animate__fadeInLeft animate__delay-0.1s"
                        alt="tea-cup"
                    />

                    <img
                        src={HeadPhone}
                        className="animate__animated animate__rollIn animate__delay-0.1"
                        alt="headphone"
                    />

                    <img
                      src={image.childImageSharp.fluid.src}
                      className="animate__animated animate__fadeInUp animate__delay-0.1"
                      alt="Banner"
                    />
                    {/*<img*/}
                    {/*    src={MainPic}*/}
                    {/*    className="animate__animated animate__fadeInUp animate__delay-0.1"*/}
                    {/*    alt="main-pic"*/}
                    {/*/>*/}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className="shape1">
        <img src={Shape1} alt="shape" />
      </div>
      {/*<div className="shape2 rotateme">*/}
      {/*  <img src={Shape2} alt="shape" />*/}
      {/*</div>*/}
      <div className="shape3">
        <img src={Shape3} alt="shape" />
      </div>
      <div className="shape4">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape5">
        <img src={Shape5} alt="shape" />
      </div>
      <div className="shape6 rotateme">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape7">
        <img src={Shape4} alt="shape" />
      </div>
      <div className="shape8 rotateme">
        <img src={Shape2} alt="shape" />
      </div>
    </div>
  )
}

export default MainBanner
