import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

// const query = graphql`
//   {
//     strapiItTopFeatures {
//       featuresCard {
//         id
//         icon
//         title
//         shortText
//         slug
//       }
//     }
//   }
// `

const query = graphql`
  {
    allStrapiServices(
        filter: { section: { eq: "MainBanner" } }
        sort: {order: ASC, fields: sortorder}
    ) {
    nodes {
      title
      section
      shortText
      slug
      icon
    }
  }
  }
`

const Features = () => {
  const data = useStaticQuery(query)
  const { allStrapiServices } = data

  return (
    <div className="boxes-area">
      <div className="container">
        <div className="row justify-content-center">
          {allStrapiServices.nodes.map((feature, index) => (
            <div className="col-lg-3 col-sm-6" key={index}>
              <Link to={`/services/${feature.slug}`}>
              <div className="single-box">
                <div className="icon">
                  <i className={feature.icon}></i>
                </div>
                <h3>{feature.title}</h3>
                <p>{feature.shortText}</p>
              </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Features