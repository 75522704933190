import React from "react"
import * as Icon from "react-feather"
import { graphql, Link, useStaticQuery } from "gatsby"

// Cloud Hosting Services Images
import BookSelf from "../../assets/images/services-right-image/book-self.png";
import Box from "../../assets/images/services-right-image/box.png";
import Chair from "../../assets/images/services-right-image/chair.png";
import Cloud from "../../assets/images/services-right-image/cloud.png";
import Cup from "../../assets/images/services-right-image/cup.png";
import FlowerTop from "../../assets/images/services-right-image/flower-top.png";
import HeadPhone from "../../assets/images/services-right-image/head-phone.png";
import Monitor from "../../assets/images/services-right-image/monitor.png";
import Mug from "../../assets/images/services-right-image/mug.png";
import Table from "../../assets/images/services-right-image/table.png";
import Tissue from "../../assets/images/services-right-image/tissue.png";
import WaterBottle from "../../assets/images/services-right-image/water-bottle.png";
import Wifi from "../../assets/images/services-right-image/wifi.png";
import CercleShape from "../../assets/images/services-right-image/cercle-shape.png";

// Design & Development Images
import BigMonitor from "../../assets/images/services-left-image/big-monitor.png";
import Creative from "../../assets/images/services-left-image/creative.png";
import Developer from "../../assets/images/services-left-image/developer.png";
import SmallMonitor from "../../assets/images/services-left-image/small-monitor.png";
import SmallTop from "../../assets/images/services-left-image/small-top.png";
import Target from "../../assets/images/services-left-image/target.png";


const query = graphql`
  {
    strapiCloudHostingServices {
      title
      shortText
      serviceList {
        id
        text
        slug
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    strapiDevelopmentService {
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      title
      shortText
      serviceList {
        id
        text
        slug
      }
    }
  }
`

const ServicesArea = () => {
  const data = useStaticQuery(query)
  const { strapiCloudHostingServices, strapiDevelopmentService } = data

  return (
    <>
      <div className="services-area ptb-80 bg-f7fafd">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h2>{strapiCloudHostingServices.title}</h2>
                <div className="bar"></div>
                <p>{strapiCloudHostingServices.shortText}</p>
              </div>
              <div className="row">
 {strapiCloudHostingServices.serviceList.map(list => (
                  <div className="col-lg-6 col-md-6" key={list.id}>
                    <Link to={`/services/${list.slug}`} className="w-100 d-block">
                    <div className="box">
                      <Icon.CheckCircle /> {list.text}
                    </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 services-right-image d-none d-lg-block">
              <img
                  src={BookSelf}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="book-self"
              />

              <img
                  src={Box}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  data-wow-delay="0.6s"
                  alt="box"
              />

              <img
                  src={Chair}
                  className="animate__animated animate__fadeInLeft animate__delay-0.2s"
                  alt="chair"
              />

              <img
                  src={Cloud}
                  className="animate__animated animate__zoomIn animate__delay-0.2s"
                  alt="cloud"
              />

              <img
                  src={Cup}
                  className="animate__animated animate__bounceIn animate__delay-0.2s"
                  alt="cup"
              />

              <img
                  src={FlowerTop}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="flower"
              />

              <img
                  src={HeadPhone}
                  className="animate__animated animate__zoomIn animate__delay-0.2s"
                  alt="head-phone"
              />

              <img
                  src={Monitor}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="monitor"
              />

              <img
                  src={Mug}
                  className="animate__animated animate__rotateIn animate__delay-0.2s"
                  alt="mug"
              />

              <img
                  src={Table}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="table"
              />

              <img
                  src={Tissue}
                  className="animate__animated animate__zoomIn animate__delay-0.2s"
                  alt="tissue"
              />

              <img
                  src={WaterBottle}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="water-bottle"
              />

              <img
                  src={Wifi}
                  className="animate__animated animate__fadeInLeft animate__delay-0.2s"
                  data-wow-delay="0.6s"
                  alt="wifi"
              />

              <img
                  src={CercleShape}
                  className="bg-image rotateme"
                  alt="shape"
              />

              <img
                  src={strapiCloudHostingServices.image.childImageSharp.fluid.src}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="Service"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="services-area ptb-80">
        <div className="container">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 services-left-image d-none d-lg-block">
              <img
                  src={BigMonitor}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="big-monitor"
              />

              <img
                  src={Creative}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="creative"
              />

              <img
                  src={Developer}
                  className="animate__animated animate__fadeInLeft animate__delay-0.2s"
                  alt="developer"
              />

              <img
                  src={FlowerTop}
                  className="animate__animated animate__fadeInLeft animate__delay-0.2s"
                  data-wow-delay="0.6s"
                  alt="flower-top"
              />

              <img
                  src={SmallMonitor}
                  className="animate__animated animate__bounceIn animate__delay-0.2s"
                  alt="small-monitor"
              />

              <img
                  src={SmallTop}
                  className="animate__animated animate__fadeInDown animate__delay-0.2s"
                  alt="small-top"
              />

              <img
                  src={Table}
                  className="animate__animated animate__zoomIn animate__delay-0.2s"
                  alt="table"
              />

              <img
                  src={Target}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="target"
              />

              <img
                  src={CercleShape}
                  className="bg-image rotateme"
                  alt="shape"
              />

              <img
                  src={strapiDevelopmentService.image.childImageSharp.fluid.src}
                  className="animate__animated animate__fadeInUp animate__delay-0.2s"
                  alt="Service"
              />

            </div>
            <div className="col-lg-6 col-md-12 services-content">
              <div className="section-title">
                <h2>{strapiDevelopmentService.title}</h2>
                <div className="bar"/>
                <p>{strapiDevelopmentService.shortText}</p>
              </div>
              <div className="row">
                {strapiDevelopmentService.serviceList.map(list => {
                  return (
                    <div className="col-lg-6 col-md-6" key={list.id}>
                      <Link to={`/services/${list.slug}`} className="w-100 d-block">
                        <div className="box">
                          <Icon.CheckCircle /> {list.text}
                        </div>
                      </Link>
                    </div>
                  )
                } )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesArea
