import React from "react"
import {Script} from "gatsby"

const MilestonesAchievedSoFar = () => {
  // const data = useStaticQuery(query)
  // const { strapiFunFacts } = data

  return (
    <div className="funfacts-area ptb-80">
      <div className="container">
        <div className="section-title">
          <h2>Don't Take Our Word for It</h2>
          <div className="bar"/>
          <p>Real Results, Real Reviews from Clutch</p>
        </div>

        <Script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"/>
        <div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="12" data-height="375"
    data-nofollow="true" data-expandifr="true" data-scale="100"  data-reviews="2105641,2102593"
    data-clutchcompany-id="1936475"/>

        {/*<Script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></Script>*/}
        {/*<div className="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="8" data-height="300"*/}
        {/*     data-nofollow="true" data-expandifr="true" data-scale="100" data-scale="100" data-reviews="2105641,2102593"*/}
        {/*     data-clutchcompany-id="1936475"></div>*/}
      </div>
    </div>
  )
}

export default MilestonesAchievedSoFar
