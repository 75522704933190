import React from "react"
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image"

const query = graphql`
    {
        file(relativePath: {eq: "StartupIndia.jpg"}) {
            childImageSharp {
                fluid(maxWidth:1000, webpQuality: 100, jpegProgressive:true, jpegQuality: 100 ) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
const StartupIndia = () => {
    const data = useStaticQuery(query)
    const {file : { childImageSharp : { fluid }}} = data
    // console.log('fluid', fluid);
    return (
        <div className="features-area pt-80 pb-50 bg-f7fafd">
            <div className="container">
                <div className="section-title">
                    <h2>Recognition</h2>
                    <div className="bar"/>
                    {/*<p>We've been Recognized by #StartUpIndia Indian Government program.</p>*/}
                </div>
                <div className="row align-items-center">

                    <div className="col-lg-6 text-center">
                    {/*<div className="col-lg-6 features-details-image">*/}
                        <a href={fluid.src} target={"_blank"} rel="noreferrer"  className="animate__animated animate__fadeInUp text-center align-items-center mx-auto w-100">
                    <Img
                        fluid={fluid}
                        className="animate__animated animate__fadeInUp text-center align-items-center mx-auto w-75"
                        alt="Start up India Certificate"
                    />
                        </a>
                    </div>
                    <div className="col-lg-6 text-center">
                        <iframe width="320" height="320" src="https://shareables.clutch.co/share/badges/1936475/27040?utm_source=clutch_top_company_badge&utm_medium=image_embed" title="Top Clutch Unity Developers India 2023"></iframe>
                    </div>
                    {/*</div>*/}

                    {/*<div className="col-lg-6 features-details">*/}
                    {/*    <div className="features-details-desc">*/}
                    {/*        <h3>We've been Recognized</h3>*/}
                    {/*        <p>*/}
                    {/*            We're a start up recognized*/}
                    {/*        </p>*/}

                    {/*        <ul className="features-details-list">*/}
                    {/*            <li>Life time supports</li>*/}
                    {/*            <li>Exclusive design</li>*/}
                    {/*            <li>Solve your problem with us</li>*/}
                    {/*            <li>We Provide Awesome Services</li>*/}
                    {/*            <li>Your business deserves best Software</li>*/}
                    {/*        </ul>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default StartupIndia